<!-- Component HTML -->
<template>
  <q-tabs v-model="tab" inline-label outside-arrows mobile-arrows no-caps>
    <q-tab
      v-for="(tab, idx) in tabs"
      :key="idx"
      :name="tab.name"
      :label="tab.label"
    />
  </q-tabs>
</template>

<!-- Component Script -->
<script lang="ts">
import { defineComponent } from "vue";

export interface TabOption {
  name: string;
  label: string;
}

export default defineComponent({
  name: "C-Tab",
  props: {
    tabs: {
      type: Array,
      required: true,
      default: () => [],
      validator: (value: TabOption[]) => {
        let valid = true;
        for (const tab of value) {
          if (!tab.name || !tab.label) {
            valid = false;
          }
        }
        return valid;
      }
    },
    value: {
      type: String,
      required: false,
      default: ""
    }
  },
  computed: {
    tab: {
      get: function(): string {
        return this.value;
      },
      set: function(newValue: string) {
        this.$emit("input", newValue);
      }
    }
  }
});
</script>

<!-- Component CSS -->
<style lang="stylus">
// Default - Mobile Viewport

// Tablet Viewport
@media screen and (min-width: 599px) {}

// Desktop Viewport
@media screen and (min-width: 1022px) {}
</style>
