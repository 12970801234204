<!-- Component HTML -->
<template>
  <div>
    <q-icon
      name="arrow_downward"
      :style="{ left: arrowPercentage + '%' }"
    ></q-icon>
    <div
      style="color:#345C99; background-image:  linear-gradient(to right, #348ABB 0%, #348ABB 10%, #97D5A4  25%, #FCFEBB 50%, #FDB567 75%, #DB474C 90%, #DB474C 100%);width:100%; height:40px;"
    >
      <div
        class="float-left"
        style="color:#FFF;height:40px;line-height:40px;padding-left:5px;"
      >
        {{ leftSide }}
      </div>
      <div
        class="float-right"
        style="height:40px;line-height:40px;padding-right:5px;color:#FFF"
      >
        {{ rightSide }}
      </div>
    </div>
    <q-icon
      name="arrow_upward"
      :style="{ left: arrowPercentage + '%' }"
    ></q-icon>
  </div>
</template>

<!-- Component Script -->
<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "Alt-Index-Bar",
  props: {
    leftSide: {
      type: String,
      required: true,
      default: "Left Side"
    },
    rightSide: {
      type: String,
      required: true,
      default: "Right Side"
    },
    arrowPercentage: {
      type: Number,
      required: true,
      default: 0
    }
  }
});
</script>

<!-- Component CSS -->
<style lang="stylus">
// Default - Mobile Viewport

// Tablet Viewport
@media screen and (min-width: 599px) {}

// Desktop Viewport
@media screen and (min-width: 1022px) {}
</style>
