<!-- Component HTML -->
<template>
  <base-card title="Top Indicators">
    <!-- Main -->
    <template v-slot:main>
      <ul>
        <li>Stock to flow converged? - Low</li>
        <li>Stock to flow model variance - Low</li>
        <li>Daily/Weekly RSI - Mid</li>
        <li>BTC 2 year moving average - Mid</li>
        <li>BTC Pi Cycle Top - High</li>
        <li>BTC Unrealized Profit/Loss- Mid</li>
        <li>Balance On Exchanges - Mid</li>
      </ul>
    </template>
    <!-- Info -->
    <!-- Thought -->
  </base-card>
</template>

<!-- DRAFT - Component Script -->
<script lang="ts">
import { defineComponent } from "vue";
import BaseCard from "@/blueprint/components/reports/Base-Card.vue";

export default defineComponent({
  name: "Indicator",
  components: {
    BaseCard
  },
  methods: {}
});
</script>

<!-- Component CSS -->
<style lang="stylus">
// Default - Mobile Viewport

// Tablet Viewport
@media screen and (min-width: 599px) {}

// Desktop Viewport
@media screen and (min-width: 1022px) {}
</style>
