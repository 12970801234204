
import { defineComponent } from "vue";
import BaseCard from "@/blueprint/components/reports/Base-Card.vue";

export default defineComponent({
  name: "Indicator",
  components: {
    BaseCard
  },
  methods: {}
});
