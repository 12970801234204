<!-- Component HTML -->
<template>
  <div class="alt-info-container">
    <p>
      <b>{{ title }}</b>
    </p>
    <p class="alt-value">{{ value }}</p>
    <alt-bar
      :leftSide="barLeft"
      :rightSide="barRight"
      :arrowPercentage="value"
    ></alt-bar>
  </div>
</template>

<!-- Component Script -->
<script lang="ts">
import { defineComponent } from "vue";
import AltIndexBar from "@/blueprint/components/reports/Alt-Index/Alt-Index-Bar.vue";

export default defineComponent({
  name: "Alt-Index-Section",
  components: {
    "alt-bar": AltIndexBar
  },
  props: {
    title: {
      type: String,
      required: true,
      default: ""
    },
    value: {
      type: Number,
      required: true,
      default: 0
    },
    barLeft: {
      type: String,
      required: true,
      default: ""
    },
    barRight: {
      type: String,
      required: true,
      default: ""
    }
  }
});
</script>

<!-- Component CSS -->
<style lang="stylus">
// Default - Mobile Viewport
.alt-info-container
  position: relative
  margin-top: 1rem
  padding-top: 1rem
  border-top: 1px solid #ededed
  &:first-of-type
    margin-top: 0
    padding-top: 0
    border-top: none

  .alt-value
    font-size: 5rem
    text-align: center
    font-weight: bold
    margin: 0

// Tablet Viewport
@media screen and (min-width: 599px) {}

// Desktop Viewport
@media screen and (min-width: 1022px) {}
</style>
