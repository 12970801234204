import { GetViaProxy } from "./proxy";

export type TaapiRSIIntervals =
  | "1m"
  | "5m"
  | "15m"
  | "30m"
  | "1h"
  | "2h"
  | "4h"
  | "12h"
  | "1d"
  | "1w";

export default class TaapiClient {
  private baseUrl = "https://api.taapi.io";
  private superSecretWhyTheFuckAreYouHereReadingThisRightNow =
    "ZXlKaGJHY2lPaUpJVXpJMU5pSXNJblI1Y0NJNklrcFhWQ0o5LmV5SmxiV0ZwYkNJNkltbGhiV3BoWTJ0emIyNXFZV052WWtCbmJXRnBiQzVqYjIwaUxDSnBZWFFpT2pFMk1UTTJPVEl5TnpZc0ltVjRjQ0k2TnpreU1EZzVNakkzTm4wLmxia2Q5X2dSc2ZuZEU4VFhzTEstT3VHMzZNMzJTRS04U1RZOHl0NWcybkk=";

  private token: string;

  constructor() {
    this.token = atob(this.superSecretWhyTheFuckAreYouHereReadingThisRightNow);
  }

  public async GetRSI(
    exchange: string,
    symbol: string,
    interval: TaapiRSIIntervals,
    retry = 0
  ): Promise<number> {
    console.log(`Attempt ${retry}`);
    const url = `${this.baseUrl}/rsi/?secret=${this.token}&exchange=${exchange}&symbol=${symbol}&interval=${interval}`;
    const response = await GetViaProxy<{ value: number; error?: string }>(url);
    if (response.status === 200) {
      // Handle 429 errors
      if (
        response.data &&
        response.data.error !== undefined &&
        response.data.error.indexOf("429") > -1 &&
        retry < 3
      ) {
        await new Promise(resolve => setTimeout(resolve, 15000));
        if (retry < 3) {
          return this.GetRSI(exchange, symbol, interval, ++retry);
        }
      }
      return response.data.value;
    }
    return Promise.reject(new Error(`Unable to get RSI level for ${interval}`));
  }
}
