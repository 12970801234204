import { render } from "./Base-Card.vue?vue&type=template&id=741bd024"
import script from "./Base-Card.vue?vue&type=script&lang=ts"
export * from "./Base-Card.vue?vue&type=script&lang=ts"

import "./Base-Card.vue?vue&type=style&index=0&id=741bd024&lang=stylus"
script.render = render

export default script
import QCard from 'quasar/src/components/card/QCard.js';
import QCardSection from 'quasar/src/components/card/QCardSection.js';
import QSeparator from 'quasar/src/components/separator/QSeparator.js';
import qInstall from "../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QCard,QCardSection,QSeparator});
