
import { defineComponent } from "vue";
import CButton from "@/blueprint/components/Button.vue";
import CLoading from "@/blueprint/components/Loading.vue";

interface Slots {
  info: boolean;
  thought: boolean;
}

export default defineComponent({
  name: "Base-Card",
  components: {
    "c-button": CButton,
    "c-loading": CLoading
  },
  props: {
    title: {
      type: String,
      required: true,
      default: null
    },
    loading: {
      type: Boolean,
      required: false,
      default: false
    },
    mainSlotStyles: {
      type: String,
      required: false,
      default: ""
    },
    refreshVoidCallback: {
      type: Function,
      required: false,
      default: null
    }
  },
  data: () => ({
    slots: {
      info: false,
      thought: false
    } as Slots // Explict casting required (Bad; I know) so that we can get type safety for methods
  }),
  computed: {
    showMain(): boolean {
      let showMain = true;
      Object.keys(this.slots).map(key => {
        if (this.slots[(key as unknown) as keyof Slots]) showMain = false;
      });
      return showMain;
    }
  },
  methods: {
    disableAllSlotsBut(invokedBySlot: keyof Slots | null = null) {
      Object.keys(this.slots).map(key => {
        if (key !== invokedBySlot) {
          this.slots[(key as unknown) as keyof Slots] = false;
        }
      });
    },
    toggleInfo() {
      this.disableAllSlotsBut("info");
      this.slots.info = !this.slots.info;
    },
    toggleThought() {
      this.disableAllSlotsBut("thought");
      this.slots.thought = !this.slots.thought;
    }
  }
});
