
import { defineComponent } from "vue";
import BaseCard from "@/blueprint/components/reports/Base-Card.vue";
import { createChart, LineStyle } from "lightweight-charts";

export default defineComponent({
  name: "Greed-Index",
  components: {
    BaseCard
  },
  methods: {
    createChart() {
      const domElement = this.$refs.chart as HTMLElement;
      if (domElement) {
        // Create the chart
        const chart = createChart(domElement);

        // Resizes the chart if viewport changes
        chart.resize(domElement.offsetWidth, 500);

        // Resize the chart to fit the content
        chart.timeScale().fitContent();

        // Hide the right price scale
        chart.applyOptions({
          rightPriceScale: {
            visible: false
          },
          grid: {
            vertLines: {
              visible: false
            },
            horzLines: {
              visible: false
            }
          },
          handleScroll: {
            vertTouchDrag: false
          },
          handleScale: {
            mouseWheel: false
          }
        });

        const lineSeries = chart.addLineSeries();

        // Configuration for the price to show
        lineSeries.applyOptions({
          priceLineVisible: false
        });

        lineSeries.applyOptions({
          color: "black",
          lineWidth: 3,
          lineStyle: LineStyle.Solid
        });

        lineSeries.setData([
          { time: "2019-04-11", value: 80.01 },
          { time: "2019-04-12", value: 96.63 },
          { time: "2019-04-13", value: 76.64 },
          { time: "2019-04-14", value: 81.89 },
          { time: "2019-04-15", value: 74.43 },
          { time: "2019-04-16", value: 80.01 },
          { time: "2019-04-17", value: 96.63 },
          { time: "2019-04-18", value: 76.64 },
          { time: "2019-04-19", value: 81.89 },
          { time: "2019-04-20", value: 74.43 }
        ]);
      }
    }
  }
});
