import { render } from "./Tab.vue?vue&type=template&id=26ad1fa2"
import script from "./Tab.vue?vue&type=script&lang=ts"
export * from "./Tab.vue?vue&type=script&lang=ts"

import "./Tab.vue?vue&type=style&index=0&id=26ad1fa2&lang=stylus"
script.render = render

export default script
import QTabs from 'quasar/src/components/tabs/QTabs.js';
import QTab from 'quasar/src/components/tabs/QTab.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QTabs,QTab});
