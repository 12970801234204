<!-- Component HTML -->
<template>
  <p>{{ title }}</p>
  <!-- Main Content Slot -->
  <slot name="main"></slot>
</template>

<!-- Component Script -->
<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "STF-Analysis-Base",
  props: {
    title: {
      type: String,
      required: true,
      default: ""
    }
  }
});
</script>

<!-- Component CSS -->
<style lang="stylus">
// Default - Mobile Viewport

// Tablet Viewport
@media screen and (min-width: 599px) {}

// Desktop Viewport
@media screen and (min-width: 1022px) {}
</style>
