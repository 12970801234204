
import { defineComponent } from "vue";
import BaseCard from "@/blueprint/components/reports/Base-Card.vue";
import AltIndexSection from "@/blueprint/components/reports/Alt-Index/Alt-Index-Section.vue";
import WebscraperClient from "@/assets/clients/webScraper";
import cheerio from "cheerio";

type CheerioElement = {
  next: () => CheerioElement;
  first: () => CheerioElement;
  text: () => string;
};
type CheerioRoot = (params: string, context: CheerioElement) => CheerioElement;

export default defineComponent({
  name: "Alt-Index",
  components: {
    BaseCard,
    "alt-section": AltIndexSection
  },
  data: () => ({
    url: "https://www.blockchaincenter.net/altcoin-season-index/",
    altcoin: {
      monthIndex: 0,
      yearIndex: 0,
      seasonIndex: 0
    },
    loading: false
  }),
  computed: {
    thought() {
      let finalThought = "";
      if (this.altcoin.monthIndex >= 75) {
        finalThought += finalThought =
          "• We are currently having a Alt Coin Month <br/>";
      }
      if (this.altcoin.yearIndex >= 75) {
        finalThought += finalThought =
          "• We are currently having a Alt Coin Year <br/>";
      }
      if (this.altcoin.seasonIndex >= 75) {
        finalThought += finalThought =
          "• We are currently in an Alt Coin Season <br/>";
      }
      return finalThought;
    }
  },
  methods: {
    webScrape() {
      this.loading = true;
      new WebscraperClient()
        .get<string>(this.url)
        .then(response => {
          if (response.status === 200) {
            // Load HTML
            const $ = cheerio.load(response.data);

            // Extract Int Value
            const extractIntValue = (
              cheerioRoot: CheerioRoot,
              cheerioElement: CheerioElement
            ): number => {
              const sibiling = cheerioElement.next().next();

              const value = cheerioRoot("div div", sibiling)
                .first()
                .text();
              const intValue = parseInt(value);
              if (!isNaN(intValue)) {
                return intValue;
              }
              throw new Error("Next cheerio element is not a number");
            };

            // Find all possible blocks where value is stored
            const seasonDOMBlock = $("#season");
            const monthDOMBlock = $("#month");
            const yearDOMBlock = $("#year");
            const dataDOMBlocks = [seasonDOMBlock, monthDOMBlock, yearDOMBlock];
            dataDOMBlocks.forEach(block => {
              const context = $("h3", block);
              const titleValue = context.text().trim();
              if (titleValue === "Altcoin Month Index") {
                this.altcoin.monthIndex = extractIntValue($, context);
              } else if (titleValue === "Altcoin Season Index") {
                this.altcoin.seasonIndex = extractIntValue($, context);
              } else if (titleValue === "Altcoin Year Index") {
                this.altcoin.yearIndex = extractIntValue($, context);
              }
            });
          }
        })
        .finally(() => {
          this.loading = false;
        });
    }
  },
  mounted() {
    this.webScrape();
  }
});
