import axios, { AxiosResponse } from "axios";

import { GetViaProxy } from "@/assets/clients/proxy";

export default class TwitterClient {
  private baseUrl = "https://api.twitter.com";
  private baseV2URL = `${this.baseUrl}/2`;

  private superSecretWhyTheFuckAreYouHereReadingThisRightNow =
    "QUFBQUFBQUFBQUFBQUFBQUFBQUFBSTk2TXdFQUFBQUE4bjM5bkQwMEhxY1lOVVMzSEVkRnk2b1RWa2MlM0R2TUtqNktUMHg2Uk9YdTlKTWZCNnNEa2ZZd3FENjU1YXZvcGxxa2xYR2dZeXZUdW55dQ==";
  private bearerToken: string;
  private client = axios;

  constructor() {
    this.bearerToken = atob(
      this.superSecretWhyTheFuckAreYouHereReadingThisRightNow
    );
    this.client.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${this.bearerToken}`;
    this.client.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
    this.client.defaults.headers.common["accept"] = "*/*";
  }

  public async GetFirstTweetOfUser(
    username: string
  ): Promise<TwitterTimelineObject> {
    const data = await this.GetRecentTweetsForUser(username);
    if (data.length > 1) {
      return data[0];
    }
    return Promise.reject(new Error("Unable to get first tweet"));
  }

  public async GetFirstTweetThatContains(
    username: string,
    text: string
  ): Promise<TwitterTimelineObject> {
    const data = await this.GetRecentTweetsForUser(username);
    for (let i = 0; i < data.length; i++) {
      const tweet = data[i];
      if (tweet.text.indexOf(text) > -1) return tweet;
    }
    return Promise.reject(
      new Error(`Unable to find any tweets that contain text: ${text}`)
    );
  }

  public async GetRecentTweetsForUser(
    username: string,
    count = 10
  ): Promise<TwitterExtendedTimelineObject[]> {
    if (count > 100 || count < 10) {
      return Promise.reject(new Error("Count has to be between 10 and 100"));
    }
    const url = `${this.baseV2URL}/tweets/search/recent?query=from:${username}&max_results=${count}&tweet.fields=created_at`;
    const response = await this.GetViaProxy<
      TwitterTweetSearchResponse<TwitterExtendedTimelineObject>
    >(url);
    if (response.status === 200) {
      const { data } = response.data;
      return data;
    }
    return Promise.reject(new Error("Unable to get recent tweets"));
  }

  public async GetTweetFromId(
    id: number
  ): Promise<{ created: string; content: string }> {
    const url = `${this.baseV2URL}/tweets?ids=${id}&tweet.fields=created_at`;
    const response = await this.GetViaProxy<TwitterGetTweetObject>(url);
    if (response.status === 200) {
      const { data } = response.data;
      const tweet = data[0];
      const unescapedContent = escape(tweet.text);
      const content = unescape(unescapedContent);
      return {
        created: tweet.created_at,
        content
      };
    }
    return Promise.reject(new Error("Unable to get tweet"));
  }

  private async GetViaProxy<T>(targetUrl: string): Promise<AxiosResponse<T>> {
    return GetViaProxy<T>(targetUrl, this.bearerToken);
  }
}

interface TwitterTweetSearchResponse<T> {
  data: T[];
  meta: {
    newest_id: number;
    oldest_id: number;
    result_count: number;
  };
}
interface TwitterTimelineObject {
  id: number;
  text: string;
}

export interface TwitterExtendedTimelineObject extends TwitterTimelineObject {
  created_at: string;
}

interface TwitterGetTweetObject {
  data: TwitterExtendedTimelineObject[];
}
