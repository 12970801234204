import { render } from "./Loading.vue?vue&type=template&id=1a279edf"
import script from "./Loading.vue?vue&type=script&lang=ts"
export * from "./Loading.vue?vue&type=script&lang=ts"

import "./Loading.vue?vue&type=style&index=0&id=1a279edf&lang=stylus"
script.render = render

export default script
import QCircularProgress from 'quasar/src/components/circular-progress/QCircularProgress.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QCircularProgress});
