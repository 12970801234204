<!-- Component HTML -->
<template>
  <base-card title="BTC Useful Charts">
    <!-- Main -->
    <template v-slot:main>
      <div
        class="chart-info"
        v-for="(chart, idx) of charts"
        :key="idx"
        @click="openChart(chart.url)"
      >
        <h6>{{ chart.title }}</h6>
        <p>{{ chart.description }}</p>
        <p class="thought"><b>Note: </b>{{ chart.thought }}</p>
      </div>
    </template>
    <!-- Info -->
    <template v-slot:info>
      <p><b>Source:</b> https://www.lookintobitcoin.com/charts</p>
    </template>
    <!-- Thought -->
    <template v-slot:thought></template>
  </base-card>
</template>

<!-- Component Script -->
<script lang="ts">
import { defineComponent } from "vue";
import BaseCard from "@/blueprint/components/reports/Base-Card.vue";

interface ChartObject {
  title: string;
  url: string;
  description: string;
  thought: string;
}

export default defineComponent({
  name: "BTC-Charts",
  components: {
    BaseCard
  },
  data: () => ({
    charts: [
      {
        title: "Bitcoin Investor Tool",
        url: "https://www.lookintobitcoin.com/charts/bitcoin-investor-tool/",
        description:
          "A good indicator as to when to invest large sums of money into Bitcoin.",
        thought:
          "Generally, you want to make big investments when it goes below the 2 Year moving average."
      },
      {
        title: "Bitcoin Sell Signal Indicator",
        url: "https://www.lookintobitcoin.com/charts/pi-cycle-top-indicator/",
        description:
          "A semi-decent indicator as to when to pull out the market.",
        thought:
          "Generally, you want to get out the market when the 111DMA crosses the 350DMA x 2 as it is an indicator of a possible top."
      },
      {
        title: "Bitcoin Relative Unrealized Profit/Loss",
        url:
          "https://www.lookintobitcoin.com/charts/relative-unrealized-profit--loss/",
        description: "A good indicator of possible market-tops.",
        thought:
          "Once it start's pushing up against the upper section of the greed zone, begin taking money out! Alternatively, when it goes into the capitulation period start pouring money back in!"
      }
    ] as ChartObject[]
  }),
  methods: {
    openChart(url: string) {
      window.open(url, "_blank");
    }
  }
});
</script>

<!-- Component CSS -->
<style lang="stylus">
// Default - Mobile Viewport
.chart-info
  border: 2px solid #E7E7E7
  border-radius: 10px
  padding: 0.5rem
  margin-top: 1rem

  &:first-of-type
    margin-top: 0rem

  h6
    margin: 0.5rem 0

  p
    color: grey
    font-size: 0.9rem

  p.thought
    color: red
    font-size: 0.8rem
// Tablet Viewportñ
@media screen and (min-width: 599px) {}

// Desktop Viewport
@media screen and (min-width: 1022px) {}
</style>
