
import { defineComponent } from "vue";
import STFBaseCard from "@/blueprint/components/reports/BTC-STF/STF-Analysis-Base.vue";

export default defineComponent({
  name: "STF-Price-Analysis",
  components: {
    "stf-base": STFBaseCard
  },
  props: {
    title: {
      type: String,
      required: true,
      default: ""
    },
    actualPrice: {
      type: Number,
      required: true,
      default: 0
    },
    targetPrice: {
      type: Number,
      required: true,
      default: 0
    }
  },
  computed: {
    overValued(): boolean {
      return this.actualPrice > this.targetPrice;
    }
  },
  methods: {
    convertNumberToDollars(value: number) {
      return new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD"
      }).format(value);
    }
  }
});
