<!-- Component HTML -->
<template>
  <div class="home">
    <twitter-feed></twitter-feed>
    <btc-rsi></btc-rsi>
    <btc-stf></btc-stf>
    <greed-index></greed-index>
    <alt-index></alt-index>
    <btc-charts></btc-charts>
    <indicators></indicators>
    <!-- <trading-view-prices></trading-view-prices> -->
    <upcoming></upcoming>
  </div>
</template>

<!-- Component Script -->
<script lang="ts">
import { defineComponent } from "vue";
import BTCSTF from "@/blueprint/components/reports/BTC-STF/index.vue";
import GreedIndex from "@/blueprint/components/reports/Greed-Index.vue";
import AltIndex from "@/blueprint/components/reports/Alt-Index/index.vue";
import Upcoming from "@/blueprint/components/reports/Upcoming.vue";
import BTCRSI from "@/blueprint/components/reports/BTC-RSI/index.vue";
import TwitterFeed from "@/blueprint/components/reports/Twitter-Feed.vue";
// import TradingViewPrices from "@/blueprint/components/reports/TradingViewPrices.vue";
import BTCCharts from "@/blueprint/components/reports/BTC-Charts.vue";
import Indicators from "../components/reports/Indicators.vue";

export default defineComponent({
  name: "Home",
  components: {
    "greed-index": GreedIndex,
    "alt-index": AltIndex,
    "btc-stf": BTCSTF,
    "btc-rsi": BTCRSI,
    upcoming: Upcoming,
    "twitter-feed": TwitterFeed,
    "btc-charts": BTCCharts,
    indicators: Indicators
    // "trading-view-prices": TradingViewPrices
  }
});
</script>

<!-- Component CSS -->
<style lang="stylus">
// Default - Mobile Viewport
.home
  padding: 0.5rem
  padding-bottom: 1rem
  display: flex
  flex-direction: column
// Tablet Viewport
@media screen and (min-width: 599px) {}

// Desktop Viewport
@media screen and (min-width: 1022px) {}
</style>
