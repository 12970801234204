
import { defineComponent } from "vue";

export interface TabOption {
  name: string;
  label: string;
}

export default defineComponent({
  name: "C-Tab",
  props: {
    tabs: {
      type: Array,
      required: true,
      default: () => [],
      validator: (value: TabOption[]) => {
        let valid = true;
        for (const tab of value) {
          if (!tab.name || !tab.label) {
            valid = false;
          }
        }
        return valid;
      }
    },
    value: {
      type: String,
      required: false,
      default: ""
    }
  },
  computed: {
    tab: {
      get: function(): string {
        return this.value;
      },
      set: function(newValue: string) {
        this.$emit("input", newValue);
      }
    }
  }
});
