
import { defineComponent } from "vue";

export default defineComponent({
  name: "STF-Analysis-Base",
  props: {
    title: {
      type: String,
      required: true,
      default: ""
    }
  }
});
