
import { defineComponent } from "vue";

export default defineComponent({
  name: "Alt-Index-Bar",
  props: {
    leftSide: {
      type: String,
      required: true,
      default: "Left Side"
    },
    rightSide: {
      type: String,
      required: true,
      default: "Right Side"
    },
    arrowPercentage: {
      type: Number,
      required: true,
      default: 0
    }
  }
});
