
import { defineComponent } from "vue";
import BaseCard from "@/blueprint/components/reports/Base-Card.vue";
import TwitterClient from "@/assets/clients/twitter";
import dayjs from "dayjs";
import STFPriceAnalysis from "@/blueprint/components/reports/BTC-STF/STF-Price-Analysis.vue";
import STFConvergenceAnalysis from "@/blueprint/components/reports/BTC-STF/STF-Convergence-Analysis.vue";
import STFVarianceAnalysis from "@/blueprint/components/reports/BTC-STF/STF-Variance-Analysis.vue";

export default defineComponent({
  name: "BTC-STF",
  components: {
    BaseCard,
    "stf-price-analysis": STFPriceAnalysis,
    "stf-convergence-analysis": STFConvergenceAnalysis,
    "stf-variance-analysis": STFVarianceAnalysis
  },
  data: () => ({
    user: "BuyBTCWW",
    stfLoading: false,
    stf: {
      daysTillNextHalving: 0,
      createdDate: "",
      price: {
        actual: 0,
        "10d": 0,
        "463d": 0
      },
      variance: {
        "10d": 0,
        "463d": 0
      },
      modelVariance: 0
    }
  }),
  methods: {
    getTweet() {
      this.stfLoading = true;
      const client = new TwitterClient();
      client
        .GetFirstTweetThatContains(this.user, "Daily stats")
        .then(tweetInfo => {
          return client.GetTweetFromId(tweetInfo.id);
        })
        .then(msg => {
          this.stf.createdDate = msg.created;
          this.parseTweet(msg.content);
        })
        .catch(e => {
          console.error(e);
        })
        .finally(() => {
          this.stfLoading = false;
        });
    },
    parseTweet(tweet: string) {
      const rows = tweet.split("\n");
      rows.map(value => {
        this.parseStockToFlowDays(value);
        this.parseStockToFlowPrice(value);
        this.parseStockToFlowActualPrice(value);
        this.parseStockToFlowDaysTillNextHalving(value);
        this.parseStockToFlowModelVariance(value);
      });
    },
    parseStockToFlowDays(value: string) {
      const regexStockToFlowDays = RegExp(/(Stock\/flow\s10d\/463d:)(.*)/gm);
      const matches = regexStockToFlowDays.exec(value);
      if (matches && matches.length === 3) {
        const stfVariance = this.splitAndTrimAndClean(matches[2], "/");
        if (stfVariance.length === 2) {
          this.stf.variance["10d"] = parseInt(stfVariance[0]);
          this.stf.variance["463d"] = parseInt(stfVariance[1]);
        }
      }
    },
    parseStockToFlowPrice(value: string) {
      const regexStockToFlowPrice = RegExp(
        /(Stock\/flow\sprice\s10d\/463d:)(.*)/gm
      );
      const matches = regexStockToFlowPrice.exec(value);
      if (matches && matches.length === 3) {
        const stfPrices = this.splitAndTrimAndClean(matches[2], "/");
        if (stfPrices.length === 2) {
          this.stf.price["10d"] = this.parseTweetPrice(stfPrices[0]);
          this.stf.price["463d"] = this.parseTweetPrice(stfPrices[1]);
        }
      }
    },
    parseStockToFlowActualPrice(value: string) {
      const regexStockToFlowActualPrice = RegExp(/(Actual price:)(.*)/gm);
      const matches = regexStockToFlowActualPrice.exec(value);
      if (matches && matches.length === 3) {
        this.stf.price.actual = this.parseTweetPrice(matches[2]);
      }
    },
    parseStockToFlowDaysTillNextHalving(value: string) {
      const regexStockToFlowActualDaysTillNextHalving = RegExp(
        /(Days to halving:)(.*)/gm
      );
      const matches = regexStockToFlowActualDaysTillNextHalving.exec(value);
      if (matches && matches.length === 3) {
        this.stf.daysTillNextHalving = parseInt(matches[2]);
      }
    },
    parseStockToFlowModelVariance(value: string) {
      const regexStocktoFlowModelVariance = RegExp(/(Mayer multiple:)(.*)/gm);
      const matches = regexStocktoFlowModelVariance.exec(value);
      if (matches && matches.length === 3) {
        this.stf.modelVariance = parseFloat(matches[2]);
      }
    },
    parseTweetPrice(value: string) {
      return parseInt(
        value
          .trim()
          .replace(".", "")
          .replace(",", "")
          .replace("$", "")
      );
    },
    splitAndTrimAndClean(value: string, splitter: string) {
      const splitValues = value
        .split(splitter)
        .map(value => value.trim())
        .filter(value => value !== "");
      return splitValues;
    },
    stockToFlowLastUpdated(date: string) {
      const lastUpdatedDate = dayjs(date);
      const currentDate = dayjs();
      const differenceHrs = currentDate.diff(lastUpdatedDate, "hours");

      return `Last updated: ${differenceHrs} hrs ago`;
    }
  },
  mounted() {
    this.getTweet();
  }
});
