<!-- Component HTML -->
<template>
  <div class="loading-container">
    <q-circular-progress
      indeterminate
      size="50px"
      color="black"
    ></q-circular-progress>
  </div>
</template>

<!-- Component Script -->
<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "Loading"
});
</script>

<!-- Component CSS -->
<style lang="stylus">
// Default - Mobile Viewport
.loading-container
  position: absolute
  background-color: white
  top: 0
  left: 0
  bottom: 0
  right: 0
  z-index: 100
  display: flex
  justify-content: center
  align-items: center
  opacity: 1;

// Tablet Viewport
@media screen and (min-width: 599px) {}

// Desktop Viewport
@media screen and (min-width: 1022px) {}
</style>
