<!-- Component HTML -->
<template>
  <q-card class="card">
    <!-- Progress -->
    <c-loading v-if="loading"></c-loading>
    <!-- Title -->
    <q-card-section>
      <div class="row">
        <div class="col-8">
          <h6 class="card-title" @click="disableAllSlotsBut()">{{ title }}</h6>
        </div>
        <div class="col-4 card-action">
          <!-- Refresh -->
          <c-button
            v-if="refreshVoidCallback !== null"
            icon="refresh"
            color="grey"
            @click="refreshVoidCallback()"
          ></c-button>
          <!-- Personal Thoughts -->
          <c-button
            icon="psychology"
            :color="slots.thought ? 'green' : 'grey'"
            @click="toggleThought()"
          ></c-button>
          <!-- Info -->
          <c-button
            icon="info"
            :color="slots.info ? 'black' : 'grey'"
            @click="toggleInfo()"
          ></c-button>
        </div>
      </div>
    </q-card-section>
    <!-- Seperator -->
    <q-separator></q-separator>
    <!-- Additional Info -->
    <q-card-section v-if="slots.info">
      <slot name="info">
        <p>TODO: Info</p>
      </slot>
    </q-card-section>
    <!-- Thought Info -->
    <q-card-section v-if="slots.thought">
      <slot name="thought">
        <p>TODO: Thought</p>
      </slot>
    </q-card-section>
    <!-- Main Content -->
    <q-card-section v-show="showMain" :style="mainSlotStyles">
      <slot name="main">
        <p>TODO: Main</p>
      </slot>
    </q-card-section>
  </q-card>
</template>

<!-- Component Script -->
<script lang="ts">
import { defineComponent } from "vue";
import CButton from "@/blueprint/components/Button.vue";
import CLoading from "@/blueprint/components/Loading.vue";

interface Slots {
  info: boolean;
  thought: boolean;
}

export default defineComponent({
  name: "Base-Card",
  components: {
    "c-button": CButton,
    "c-loading": CLoading
  },
  props: {
    title: {
      type: String,
      required: true,
      default: null
    },
    loading: {
      type: Boolean,
      required: false,
      default: false
    },
    mainSlotStyles: {
      type: String,
      required: false,
      default: ""
    },
    refreshVoidCallback: {
      type: Function,
      required: false,
      default: null
    }
  },
  data: () => ({
    slots: {
      info: false,
      thought: false
    } as Slots // Explict casting required (Bad; I know) so that we can get type safety for methods
  }),
  computed: {
    showMain(): boolean {
      let showMain = true;
      Object.keys(this.slots).map(key => {
        if (this.slots[(key as unknown) as keyof Slots]) showMain = false;
      });
      return showMain;
    }
  },
  methods: {
    disableAllSlotsBut(invokedBySlot: keyof Slots | null = null) {
      Object.keys(this.slots).map(key => {
        if (key !== invokedBySlot) {
          this.slots[(key as unknown) as keyof Slots] = false;
        }
      });
    },
    toggleInfo() {
      this.disableAllSlotsBut("info");
      this.slots.info = !this.slots.info;
    },
    toggleThought() {
      this.disableAllSlotsBut("thought");
      this.slots.thought = !this.slots.thought;
    }
  }
});
</script>

<!-- Component CSS -->
<style lang="stylus">
// Default - Mobile Viewport
.card
  position: relative
  margin-top: 1rem
  &:first-of-type
    margin-top: 0

  .card-title
    font-size: 1.25rem
    font-weight: 500
    line-height: 3rem
    letter-spacing: 0.0125em
    height: 100%
    margin: 0
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently
                          supported by Chrome, Edge, Opera and Firefox */

  .card-action
    display: flex
    align-items: center
    justify-content: flex-end

  .card-no-padding
    padding: 0

// Tablet Viewport
@media screen and (min-width: 599px) {}

// Desktop Viewport
@media screen and (min-width: 1022px) {}
</style>
