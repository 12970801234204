
import { defineComponent } from "vue";
import BaseCard from "@/blueprint/components/reports/Base-Card.vue";
import CTab, { TabOption } from "@/blueprint/components/Tab.vue";
import TwitterClient, {
  TwitterExtendedTimelineObject
} from "@/assets/clients/twitter";

import TimeAgo from "javascript-time-ago";
import en from "javascript-time-ago/locale/en";
TimeAgo.addDefaultLocale(en);

interface SwipeEvent {
  direction: "left" | "right" | "down" | "up";
}

export default defineComponent({
  name: "Twitter-Feed",
  components: {
    BaseCard,
    "c-tab": CTab
  },
  data: () => ({
    tabs: [
      {
        name: "Cameronfous",
        label: "Cameron Fous"
      },
      {
        name: "michaeljburry",
        label: "Michael Burry"
      },
      {
        name: "elonmusk",
        label: "Elon Musk"
      },
      {
        name: "100trillionUSD",
        label: "Plan B"
      },
      {
        name: "BarrySilbert",
        label: "Barry Silbert"
      },
      {
        name: "WuBlockchain",
        label: "Wu Blockchain"
      },
      {
        name: "Nicholas_Merten",
        label: "Data Dash"
      },
      {
        name: "MMCrypto",
        label: "MM Crypto"
      },
      {
        name: "TylerSCrypto",
        label: "Tyler S"
      },
      {
        name: "michael_saylor",
        label: "Michael Saylor"
      },
      {
        name: "tyler",
        label: "Tyler Winklevoss"
      },
      {
        name: "attorneyjeremy1",
        label: "Legal Briefs"
      }
    ] as TabOption[],
    currentTab: "Cameronfous",
    currentTabTweets: [] as TwitterExtendedTimelineObject[],
    loading: false,
    timeAgoClient: {} as TimeAgo,
    twitterClient: {} as TwitterClient
  }),
  watch: {
    currentTab() {
      this.getFeed();
    }
  },
  methods: {
    getTweets(user: string) {
      this.loading = true;
      this.twitterClient
        .GetRecentTweetsForUser(user, 25)
        .then(response => {
          this.currentTabTweets = response;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getTimeAgo(value: string): string {
      const date = new Date(value);
      return this.timeAgoClient.format(date);
    },
    openTwitterOnTweet(tweetId: string) {
      const twitterUrl = "https://twitter.com";
      const finalUrl = `${twitterUrl}/${this.currentTab}/status/${tweetId}`;
      const tabOpeningOptions: "_blank" | "_parent" | "_self" | "_top" =
        "_blank";
      window.open(finalUrl, tabOpeningOptions);
    },
    swipeHandlerLeftOrRight(e: SwipeEvent) {
      for (let i = 0; i < this.tabs.length; i++) {
        const tab = this.tabs[i];
        const targetIndex = e.direction === "left" ? i + 1 : i - 1;
        if (
          tab.name === this.currentTab &&
          this.tabs[targetIndex] !== undefined
        ) {
          this.currentTab = this.tabs[targetIndex].name;
          break;
        }
      }
    },
    getFeed() {
      this.getTweets(this.currentTab);
    }
  },
  created() {
    const iETFLanguageTag = "en-GB";
    this.timeAgoClient = new TimeAgo(iETFLanguageTag);
    this.twitterClient = new TwitterClient();
  },
  mounted() {
    this.getFeed();
  }
});
