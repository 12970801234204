import axios, { AxiosResponse } from "axios";

const useLocal = false;
const cloudUrl = "https://api-crypto.azurewebsites.net/api/proxy";
const localUrl = "http://localhost:7071/api/proxy";

export const PROXY_SERVER_URL = useLocal ? localUrl : cloudUrl;

export async function GetViaProxy<T>(
  targetUrl: string,
  token = ""
): Promise<AxiosResponse<T>> {
  const finalUrl = `${PROXY_SERVER_URL}?url=${btoa(targetUrl)}&token=${btoa(
    token
  )}`;
  const response = await axios({
    method: "GET",
    url: finalUrl
  });
  return response;
}
