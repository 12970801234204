
import { defineComponent } from "vue";
import STFBaseCard from "@/blueprint/components/reports/BTC-STF/STF-Analysis-Base.vue";

export default defineComponent({
  name: "STF-Variance-Analysis",
  components: {
    "stf-base": STFBaseCard
  },
  props: {
    title: {
      type: String,
      required: true,
      default: ""
    },
    actualPrice: {
      type: Number,
      required: true,
      default: 0
    },
    targetPrice: {
      type: Number,
      required: true,
      default: 0
    },
    variance: {
      type: Number,
      required: true,
      default: 0
    }
  },
  computed: {
    overValued(): boolean {
      return this.actualPrice > this.targetPrice;
    }
  },
  methods: {
    analyseVariance() {
      if (!this.overValued && this.variance > 0.5) {
        return "Undervalued Significantly";
      }

      if (!this.overValued && 0.5 > this.variance && this.variance > 0.25) {
        return "Undervalued";
      }

      if (!this.overValued && 0.25 >= this.variance && this.variance > 0.0) {
        return "Slightly Undervalued";
      }

      if (!this.overValued && 0.25 >= this.variance && this.variance > 0.0) {
        return "Slightly Overvalued";
      }

      if (this.overValued && 0.5 > this.variance && this.variance > 0.25) {
        return "Overvalued";
      }

      if (this.overValued && this.variance > 0.5) {
        return "Overvalued Significantly";
      }

      return "Neutral";
    }
  }
});
