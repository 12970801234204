<!-- Component HTML -->
<template>
  <base-card
    title="Alt Coin Index"
    :loading="loading"
    :refreshVoidCallback="this.webScrape"
  >
    <!-- Main -->
    <template v-slot:main>
      <alt-section
        title="Altcoin Season Index"
        :value="altcoin.seasonIndex"
        barLeft="Bitcoin Season"
        barRight="Altcoin Season"
      ></alt-section>
      <alt-section
        title="Altcoin Month Index"
        :value="altcoin.monthIndex"
        barLeft="Bitcoin Month"
        barRight="Altcoin Month"
      ></alt-section>
      <alt-section
        title="Altcoin Year Index"
        :value="altcoin.yearIndex"
        barLeft="Bitcoin Year"
        barRight="Altcoin Year"
      ></alt-section>
    </template>
    <!-- Info -->
    <template v-slot:info>
      <p>
        Alt Coins (Alternative Coins) are any blockchain based token/currency
        that is not Bitcoin.
      </p>
      <p>
        When Bitcoin begins it's parabolic run; during points of stagnation or
        blow-off top. The wealth is transfered to the Alt Coin market. The
        indicators help determine if this transfer of wealth has started to
        occur or not.
      </p>
      <p style="color: red">
        <b
          >The biggest gains usually can be made in the Alt Coin market but it
          is subsequently the riskiest</b
        >
      </p>
      <p>
        <b>What is an Altcoin Season?</b> <br />If 75% of the Top 50 coins
        performed better than Bitcoin over the last season (90 days) it is
        Altcoin Season.
      </p>
      <p>
        <b>What is an Altcoin Month?</b> <br />If 75% of the Top 50 coins
        performed better than Bitcoin over the last 30 days it is Altcoin Month.
      </p>
      <p>
        <b>What is an Altcoin Year?</b> <br />If 75% of the Top 50 coins
        performed better than Bitcoin over the last year it is Altcoin Year.
      </p>
    </template>
    <!-- Thought -->
    <template v-slot:thought>
      <p v-html="thought"></p>
    </template>
  </base-card>
</template>

<!-- Component Script -->
<script lang="ts">
import { defineComponent } from "vue";
import BaseCard from "@/blueprint/components/reports/Base-Card.vue";
import AltIndexSection from "@/blueprint/components/reports/Alt-Index/Alt-Index-Section.vue";
import WebscraperClient from "@/assets/clients/webScraper";
import cheerio from "cheerio";

type CheerioElement = {
  next: () => CheerioElement;
  first: () => CheerioElement;
  text: () => string;
};
type CheerioRoot = (params: string, context: CheerioElement) => CheerioElement;

export default defineComponent({
  name: "Alt-Index",
  components: {
    BaseCard,
    "alt-section": AltIndexSection
  },
  data: () => ({
    url: "https://www.blockchaincenter.net/altcoin-season-index/",
    altcoin: {
      monthIndex: 0,
      yearIndex: 0,
      seasonIndex: 0
    },
    loading: false
  }),
  computed: {
    thought() {
      let finalThought = "";
      if (this.altcoin.monthIndex >= 75) {
        finalThought += finalThought =
          "• We are currently having a Alt Coin Month <br/>";
      }
      if (this.altcoin.yearIndex >= 75) {
        finalThought += finalThought =
          "• We are currently having a Alt Coin Year <br/>";
      }
      if (this.altcoin.seasonIndex >= 75) {
        finalThought += finalThought =
          "• We are currently in an Alt Coin Season <br/>";
      }
      return finalThought;
    }
  },
  methods: {
    webScrape() {
      this.loading = true;
      new WebscraperClient()
        .get<string>(this.url)
        .then(response => {
          if (response.status === 200) {
            // Load HTML
            const $ = cheerio.load(response.data);

            // Extract Int Value
            const extractIntValue = (
              cheerioRoot: CheerioRoot,
              cheerioElement: CheerioElement
            ): number => {
              const sibiling = cheerioElement.next().next();

              const value = cheerioRoot("div div", sibiling)
                .first()
                .text();
              const intValue = parseInt(value);
              if (!isNaN(intValue)) {
                return intValue;
              }
              throw new Error("Next cheerio element is not a number");
            };

            // Find all possible blocks where value is stored
            const seasonDOMBlock = $("#season");
            const monthDOMBlock = $("#month");
            const yearDOMBlock = $("#year");
            const dataDOMBlocks = [seasonDOMBlock, monthDOMBlock, yearDOMBlock];
            dataDOMBlocks.forEach(block => {
              const context = $("h3", block);
              const titleValue = context.text().trim();
              if (titleValue === "Altcoin Month Index") {
                this.altcoin.monthIndex = extractIntValue($, context);
              } else if (titleValue === "Altcoin Season Index") {
                this.altcoin.seasonIndex = extractIntValue($, context);
              } else if (titleValue === "Altcoin Year Index") {
                this.altcoin.yearIndex = extractIntValue($, context);
              }
            });
          }
        })
        .finally(() => {
          this.loading = false;
        });
    }
  },
  mounted() {
    this.webScrape();
  }
});
</script>

<!-- Component CSS -->
<style lang="stylus">
// Default - Mobile Viewport



// Tablet Viewportñ
@media screen and (min-width: 599px) {}

// Desktop Viewport
@media screen and (min-width: 1022px) {}
</style>
