<!-- Component HTML -->
<template>
  <base-card
    title="Twitter Feed"
    :loading="loading"
    :refreshVoidCallback="this.getFeed"
  >
    <!-- Main -->
    <template v-slot:main>
      <!-- Tabs -->
      <c-tab :tabs="tabs" v-model="currentTab"></c-tab>
      <!-- Tweet Timeline -->
      <div
        class="tweet-timeline"
        v-touch-swipe.mouse.left="swipeHandlerLeftOrRight"
        v-touch-swipe.mouse.right="swipeHandlerLeftOrRight"
      >
        <q-list>
          <q-item
            v-for="tweet in currentTabTweets"
            :key="tweet.id"
            @click="openTwitterOnTweet(tweet.id)"
            clickable
          >
            <q-item-section>
              <q-item-label caption>{{ tweet.text }}</q-item-label>
            </q-item-section>

            <q-item-section side top>
              <q-item-label caption>{{
                getTimeAgo(tweet.created_at)
              }}</q-item-label>
            </q-item-section>
          </q-item>
        </q-list>
      </div>
    </template>
    <!-- Info -->
    <template v-slot:info>
      <p>• Most recent Twitter feed for some of people.</p>
      <p>
        • Either they do good TA (Technical Analysis) e.g. DataDash on Youtube
      </p>
      <p>• Or they are influencial in the crypto space e.g. Elon Musk</p>
    </template>
    <!-- Thought -->
    <!-- <template v-slot:thought> </template> -->
  </base-card>
</template>

<!-- Component Script -->
<script lang="ts">
import { defineComponent } from "vue";
import BaseCard from "@/blueprint/components/reports/Base-Card.vue";
import CTab, { TabOption } from "@/blueprint/components/Tab.vue";
import TwitterClient, {
  TwitterExtendedTimelineObject
} from "@/assets/clients/twitter";

import TimeAgo from "javascript-time-ago";
import en from "javascript-time-ago/locale/en";
TimeAgo.addDefaultLocale(en);

interface SwipeEvent {
  direction: "left" | "right" | "down" | "up";
}

export default defineComponent({
  name: "Twitter-Feed",
  components: {
    BaseCard,
    "c-tab": CTab
  },
  data: () => ({
    tabs: [
      {
        name: "Cameronfous",
        label: "Cameron Fous"
      },
      {
        name: "michaeljburry",
        label: "Michael Burry"
      },
      {
        name: "elonmusk",
        label: "Elon Musk"
      },
      {
        name: "100trillionUSD",
        label: "Plan B"
      },
      {
        name: "BarrySilbert",
        label: "Barry Silbert"
      },
      {
        name: "WuBlockchain",
        label: "Wu Blockchain"
      },
      {
        name: "Nicholas_Merten",
        label: "Data Dash"
      },
      {
        name: "MMCrypto",
        label: "MM Crypto"
      },
      {
        name: "TylerSCrypto",
        label: "Tyler S"
      },
      {
        name: "michael_saylor",
        label: "Michael Saylor"
      },
      {
        name: "tyler",
        label: "Tyler Winklevoss"
      },
      {
        name: "attorneyjeremy1",
        label: "Legal Briefs"
      }
    ] as TabOption[],
    currentTab: "Cameronfous",
    currentTabTweets: [] as TwitterExtendedTimelineObject[],
    loading: false,
    timeAgoClient: {} as TimeAgo,
    twitterClient: {} as TwitterClient
  }),
  watch: {
    currentTab() {
      this.getFeed();
    }
  },
  methods: {
    getTweets(user: string) {
      this.loading = true;
      this.twitterClient
        .GetRecentTweetsForUser(user, 25)
        .then(response => {
          this.currentTabTweets = response;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getTimeAgo(value: string): string {
      const date = new Date(value);
      return this.timeAgoClient.format(date);
    },
    openTwitterOnTweet(tweetId: string) {
      const twitterUrl = "https://twitter.com";
      const finalUrl = `${twitterUrl}/${this.currentTab}/status/${tweetId}`;
      const tabOpeningOptions: "_blank" | "_parent" | "_self" | "_top" =
        "_blank";
      window.open(finalUrl, tabOpeningOptions);
    },
    swipeHandlerLeftOrRight(e: SwipeEvent) {
      for (let i = 0; i < this.tabs.length; i++) {
        const tab = this.tabs[i];
        const targetIndex = e.direction === "left" ? i + 1 : i - 1;
        if (
          tab.name === this.currentTab &&
          this.tabs[targetIndex] !== undefined
        ) {
          this.currentTab = this.tabs[targetIndex].name;
          break;
        }
      }
    },
    getFeed() {
      this.getTweets(this.currentTab);
    }
  },
  created() {
    const iETFLanguageTag = "en-GB";
    this.timeAgoClient = new TimeAgo(iETFLanguageTag);
    this.twitterClient = new TwitterClient();
  },
  mounted() {
    this.getFeed();
  }
});
</script>

<!-- Component CSS -->
<style lang="stylus">
// Default - Mobile Viewport
.tweet-timeline
  height: 20rem
  overflow-y: auto

// Tablet Viewport
@media screen and (min-width: 599px) {}

// Desktop Viewport
@media screen and (min-width: 1022px) {}
</style>
