<!-- Component HTML -->
<template>
  <div class="stf-overview-container">
    <h6 class="stf-overview">
      Stock to Flow has {{ !stfConverged ? "not" : "" }} converged
    </h6>
    <p class="stf-subpoint" v-if="!stfConverged">
      (Bull market)
    </p>
    <p class="stf-subpoint" v-else>
      (Bear market)
    </p>
  </div>
</template>

<!-- Component Script -->
<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "STF-Convergence-Analysis",
  props: {
    tenDaySTF: {
      type: Number,
      required: true,
      default: 0
    },
    fourSixThreeDaySTF: {
      type: Number,
      required: true,
      default: 0
    }
  },
  computed: {
    stfConverged(): boolean {
      const mean = (this.tenDaySTF + this.fourSixThreeDaySTF) / 2;
      const diff = this.tenDaySTF - this.fourSixThreeDaySTF;
      const value = Math.abs((diff / mean) * 100);
      // If the STF difference between 10d and 463d is less than 1% the model has convereged
      return value < 1;
    }
  }
});
</script>

<!-- Component CSS -->
<style lang="stylus">
// Default - Mobile Viewport
.stf-overview-container
  text-align: center
  .stf-overview
    margin: 0
    margin-top: 2rem
    text-align: center

.stf-subpoint
  margin: 0
  color: grey

// Tablet Viewport
@media screen and (min-width: 599px) {}

// Desktop Viewport
@media screen and (min-width: 1022px) {}
</style>
