import { render } from "./Alt-Index-Bar.vue?vue&type=template&id=0d034904"
import script from "./Alt-Index-Bar.vue?vue&type=script&lang=ts"
export * from "./Alt-Index-Bar.vue?vue&type=script&lang=ts"

import "./Alt-Index-Bar.vue?vue&type=style&index=0&id=0d034904&lang=stylus"
script.render = render

export default script
import QIcon from 'quasar/src/components/icon/QIcon.js';
import qInstall from "../../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QIcon});
