<!-- Component HTML -->
<template>
  <stf-base :title="title">
    <template v-slot:main>
      <ul>
        <li>
          BTC price should be <b>{{ convertNumberToDollars(targetPrice) }}</b>
        </li>
        <li>
          BTC price currently is
          <b>{{ convertNumberToDollars(actualPrice) }}</b>
        </li>
        <li>
          Evaluation:
          <b :style="{ color: overValued ? 'red' : 'green' }">{{
            overValued ? "Overvalued" : "Undervalued"
          }}</b>
        </li>
      </ul>
    </template>
  </stf-base>
</template>

<!-- Component Script -->
<script lang="ts">
import { defineComponent } from "vue";
import STFBaseCard from "@/blueprint/components/reports/BTC-STF/STF-Analysis-Base.vue";

export default defineComponent({
  name: "STF-Price-Analysis",
  components: {
    "stf-base": STFBaseCard
  },
  props: {
    title: {
      type: String,
      required: true,
      default: ""
    },
    actualPrice: {
      type: Number,
      required: true,
      default: 0
    },
    targetPrice: {
      type: Number,
      required: true,
      default: 0
    }
  },
  computed: {
    overValued(): boolean {
      return this.actualPrice > this.targetPrice;
    }
  },
  methods: {
    convertNumberToDollars(value: number) {
      return new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD"
      }).format(value);
    }
  }
});
</script>

<!-- Component CSS -->
<style lang="stylus">
// Default - Mobile Viewport

// Tablet Viewport
@media screen and (min-width: 599px) {}

// Desktop Viewport
@media screen and (min-width: 1022px) {}
</style>
