
import { defineComponent } from "vue";
import BaseCard from "@/blueprint/components/reports/Base-Card.vue";

interface ChartObject {
  title: string;
  url: string;
  description: string;
  thought: string;
}

export default defineComponent({
  name: "BTC-Charts",
  components: {
    BaseCard
  },
  data: () => ({
    charts: [
      {
        title: "Bitcoin Investor Tool",
        url: "https://www.lookintobitcoin.com/charts/bitcoin-investor-tool/",
        description:
          "A good indicator as to when to invest large sums of money into Bitcoin.",
        thought:
          "Generally, you want to make big investments when it goes below the 2 Year moving average."
      },
      {
        title: "Bitcoin Sell Signal Indicator",
        url: "https://www.lookintobitcoin.com/charts/pi-cycle-top-indicator/",
        description:
          "A semi-decent indicator as to when to pull out the market.",
        thought:
          "Generally, you want to get out the market when the 111DMA crosses the 350DMA x 2 as it is an indicator of a possible top."
      },
      {
        title: "Bitcoin Relative Unrealized Profit/Loss",
        url:
          "https://www.lookintobitcoin.com/charts/relative-unrealized-profit--loss/",
        description: "A good indicator of possible market-tops.",
        thought:
          "Once it start's pushing up against the upper section of the greed zone, begin taking money out! Alternatively, when it goes into the capitulation period start pouring money back in!"
      }
    ] as ChartObject[]
  }),
  methods: {
    openChart(url: string) {
      window.open(url, "_blank");
    }
  }
});
