<!-- Component HTML -->
<template>
  <base-card title="Bitcoin Greed Index">
    <!-- Main -->
    <template v-slot:main>
      <!-- Main -->
      <img
        src="https://alternative.me/crypto/fear-and-greed-index.png"
        alt="Bitcoin Fear & Greed Index"
        style="width: 100%;"
      />
      <!-- Graph -->
      <!-- <div class="graph">
        <div ref="chart"></div>
      </div> -->
    </template>
    <!-- Info -->
    <template v-slot:info>
      <p>
        <b>Warren Buffet:</b> Be greedy when everyone is fearful, be fearful
        when everyone is greedy!
      </p>
      <p>• When the greed levels are high, expect a crash at any moment.</p>
      <p>
        • When the greed levels are low, this is a good time to buy in and HODL!
      </p>
    </template>
    <!-- Thought -->
    <template v-slot:thought>
      <p>
        <b>TODO:</b> Webscrape the site; look at prior values and determine a
        opinion on the values.
        <br />
        • Seems like this will require a API -> Serverless function library act
        as a proxy server to get content and pass down
      </p>
    </template>
  </base-card>
</template>

<!-- Component Script -->
<script lang="ts">
import { defineComponent } from "vue";
import BaseCard from "@/blueprint/components/reports/Base-Card.vue";
import { createChart, LineStyle } from "lightweight-charts";

export default defineComponent({
  name: "Greed-Index",
  components: {
    BaseCard
  },
  methods: {
    createChart() {
      const domElement = this.$refs.chart as HTMLElement;
      if (domElement) {
        // Create the chart
        const chart = createChart(domElement);

        // Resizes the chart if viewport changes
        chart.resize(domElement.offsetWidth, 500);

        // Resize the chart to fit the content
        chart.timeScale().fitContent();

        // Hide the right price scale
        chart.applyOptions({
          rightPriceScale: {
            visible: false
          },
          grid: {
            vertLines: {
              visible: false
            },
            horzLines: {
              visible: false
            }
          },
          handleScroll: {
            vertTouchDrag: false
          },
          handleScale: {
            mouseWheel: false
          }
        });

        const lineSeries = chart.addLineSeries();

        // Configuration for the price to show
        lineSeries.applyOptions({
          priceLineVisible: false
        });

        lineSeries.applyOptions({
          color: "black",
          lineWidth: 3,
          lineStyle: LineStyle.Solid
        });

        lineSeries.setData([
          { time: "2019-04-11", value: 80.01 },
          { time: "2019-04-12", value: 96.63 },
          { time: "2019-04-13", value: 76.64 },
          { time: "2019-04-14", value: 81.89 },
          { time: "2019-04-15", value: 74.43 },
          { time: "2019-04-16", value: 80.01 },
          { time: "2019-04-17", value: 96.63 },
          { time: "2019-04-18", value: 76.64 },
          { time: "2019-04-19", value: 81.89 },
          { time: "2019-04-20", value: 74.43 }
        ]);
      }
    }
  }
});
</script>

<!-- Component CSS -->
<style lang="stylus">
// Default - Mobile Viewport

// Tablet Viewportñ
@media screen and (min-width: 599px) {}

// Desktop Viewport
@media screen and (min-width: 1022px) {}
</style>
