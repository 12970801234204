<!-- Component HTML -->
<template>
  <base-card
    title="BTC - RSI Analysis"
    :loading="loading"
    :refreshVoidCallback="this.getRSILevels"
  >
    <!-- Main -->
    <template v-slot:main>
      <div class="btc-rsi-container">
        <p><b>Exchange: </b>{{ captialise(exchange) }}</p>
        <p><b>Market Pair: </b>{{ symbol }}</p>
        <p><b>Note: </b>Slowness caused by rated limited API (Free tier)</p>
        <!-- RSI Levels -->
        <div class="rsi-container">
          <div
            class="rsi-level-container"
            v-for="rsiLevel in Object.keys(rsi)"
            :key="rsiLevel"
          >
            <!-- Skeleton Loading -->
            <q-skeleton
              v-if="!rsi[rsiLevel].loaded"
              type="QAvatar"
              size="5rem"
            ></q-skeleton>
            <!-- Actual RSI Level -->
            <div
              v-else
              class="rsi-level"
              :style="{
                'background-color': getRSIStatus(rsi[rsiLevel].value)
                  .background,
                color: getRSIStatus(rsi[rsiLevel].value).text
              }"
            >
              {{ rsiLevel + ": " + roundRSI(rsi[rsiLevel].value) }}
            </div>
          </div>
        </div>
        <!-- Determine when to buy -->
        <h6 class="rsi-title">Good time to buy?</h6>
        <p class="rsi-answer">{{ goodTimeToBuy }}</p>
        <p class="rsi-sub">(See "thoughts" for more info)</p>
      </div>
    </template>
    <!-- Info -->
    <template v-slot:info>
      <p>• RSI levels help determine when to buy and sell.</p>
    </template>
    <!-- Thought -->
    <template v-slot:thought>
      <div class="thought-container">
        <!-- Title -->
        <h6 class="thought-title">Is it a good time to buy?</h6>
        <!-- Analysis; 1W -->
        <p class="thought-point">
          On a <b>weekly level</b> the current RSI is
          <b>{{ roundRSI(rsi["1w"].value) }}</b
          >. The ULTIMATE; and I MEAN ULTIMATE time to buy is when the RSI is
          <b>{{ rsi["1w"].buyPoint }} or below</b>.
        </p>
        <!-- Analysis; 1D -->
        <p class="thought-point">
          On a <b>daily level</b> the current RSI is
          <b>{{ roundRSI(rsi["1d"].value) }}</b
          >. A excellent time to buy is when the RSI is
          <b>{{ rsi["1d"].buyPoint }} or below</b>.
        </p>
        <!-- Analysis; 1D -->
        <p class="thought-point">
          On a <b>hourly level</b> the current RSI is
          <b>{{ roundRSI(rsi["1h"].value) }}</b
          >. A good time to buy is when the RSI is
          <b>{{ rsi["1h"].buyPoint }} or below</b>.
        </p>
        <!-- Analysis: General -->
        <h6 class="thought-title">Thoughts on buying!</h6>
        <p class="thought-point">
          <b
            >Look at the number of the days the daily candles have been
            consecutively GREEN! If its greater than 5; be cautious as
            retracement is due!</b
          >
        </p>
        <p class="thought-point">
          When performing purchases look at the RSI level for the day.
          <b
            >NEVER EVER BUY if the RSI on the (Hourly & Daily & Weekly) are
            above 70</b
          >
          (Fundamentally you are buying a top).
        </p>
        <p class="thought-point">
          If you must purchase ensure the (Hourly & Daily) are below 70 but even
          then I'd avoid this, like both would have to be at mid-level or
          low-ish for it to be worth it.
        </p>
        <p class="thought-point">
          Ideally, determine the level at the (Hourly) RSI would be low (Convert
          RSI to £) and then place a buy order there and be patient!
        </p>
      </div>
    </template>
  </base-card>
</template>

<!-- Component Script -->
<script lang="ts">
import { defineComponent } from "vue";
import BaseCard from "@/blueprint/components/reports/Base-Card.vue";
import TaapiClient, { TaapiRSIIntervals } from "@/assets/clients/taapi";
import ShrimpyClient, {
  CandleData,
  ShrimpyCandleIntervals,
  KnownExchanges
} from "@/assets/clients/shrimpy";

interface RSIColorMapping {
  background: string;
  text: string;
}

interface RSIInfo {
  buyPoint: number;
  limitPoint: number;
  value: number;
  loaded: boolean;
}

type GoodTimeToBuyOptions = "Yes" | "Maybe" | "No";

// TODO: Calculate the RSI price at: 0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100
// TODO: Clicking on the RSI -> Transforms to graph that then gets back prior RSI levels and shows the above TODOs
// TODO: BTC Correction Predictor: Based on green-candles and the last time the candles touched the daily
// TODO: Potential analysis on the last time it hit this RSI level + How often it hits this average

export default defineComponent({
  name: "BTC-RSI",
  components: {
    BaseCard
  },
  data: () => ({
    loading: false,
    exchange: "binance" as KnownExchanges,
    symbol: "BTC/GBP",
    rsiInit: false,
    rsi: {
      "1h": {
        buyPoint: 30,
        limitPoint: 70,
        value: 0,
        loaded: false
      },
      "1d": {
        buyPoint: 35,
        limitPoint: 50,
        value: 0,
        loaded: false
      },
      "1w": {
        buyPoint: 40,
        limitPoint: 70,
        value: 0,
        loaded: false
      }
    } as Record<TaapiRSIIntervals, RSIInfo>
  }),
  computed: {
    goodTimeToBuy(): GoodTimeToBuyOptions {
      if (!this.rsiInit) {
        return "No";
      }

      let answer: GoodTimeToBuyOptions = "No";

      // Determine if current values are between RSI Levels
      if (
        this.rsiBetweenLevels(this.rsi["1w"]) ||
        this.rsiBetweenLevels(this.rsi["1d"]) ||
        this.rsiBetweenLevels(this.rsi["1h"])
      ) {
        answer = "Maybe";
      }

      // Determine if current values are less than RSI Levels
      if (
        this.rsiLessThanBuyPoint(this.rsi["1w"]) ||
        this.rsiLessThanBuyPoint(this.rsi["1d"]) ||
        this.rsiLessThanBuyPoint(this.rsi["1h"])
      ) {
        answer = "Yes";
      }

      return answer;
    }
  },
  methods: {
    async getRSILevels() {
      const taapiClient = new TaapiClient();
      const shrimpyClient = new ShrimpyClient();

      const otherIntervals = Object.keys(this.rsi) as Array<
        ShrimpyCandleIntervals | TaapiRSIIntervals
      >;

      const setRsiInitToTrue = () => {
        if (this.rsiInit) {
          return;
        }
        this.rsiInit = true;
      };

      for (const interval of otherIntervals) {
        this.rsi[interval].loaded = false;

        if (interval === "1h" || interval === "1d") {
          shrimpyClient
            .GetCandlesFor("BTC/GBP", this.exchange, interval)
            .then(data => {
              this.rsi[interval].value = this.calculateTradingViewRsi(data);
              this.rsi[interval].loaded = true;
              setRsiInitToTrue();
            })
            .catch(error => console.error(error));
        }

        if (interval === "1w") {
          taapiClient
            .GetRSI(this.exchange, this.symbol, interval)
            .then(value => {
              this.rsi[interval].value = value;
              this.rsi[interval].loaded = true;
              setRsiInitToTrue();
            })
            .catch(error => console.error(error));
        }
      }
    },
    calculateTradingViewRsi(candles: CandleData[]) {
      const period = 14;

      const { increases, decreases } = this.calculateCandlePriceDifferences(
        candles,
        candles.length
      );

      let avgU = 0;
      let avgD = 0;
      const smoothing = 1 / period;
      for (let x = 0; x < candles.length; x++) {
        avgU = smoothing * increases[x] + (1 - smoothing) * avgU;
        avgD = smoothing * decreases[x] + (1 - smoothing) * avgD;
      }

      const rs = avgU / avgD;
      const rsi = 100 - 100 / (1 + rs);

      return rsi;
    },
    calculateCandlePriceDifferences(
      candles: CandleData[],
      period: number
    ): { increases: number[]; decreases: number[] } {
      if (candles.length < period) {
        throw new Error(
          "calculateCandlePriceDifferences - Not enough data points"
        );
      }

      const increases: number[] = [0];
      const decreases: number[] = [0];

      for (let x = 0; x < period - 1; x++) {
        const change =
          parseFloat(candles[x + 1].close) - parseFloat(candles[x].close);

        if (change > 0) {
          increases.push(change);
          decreases.push(0);
        } else if (change < 0) {
          decreases.push(change * -1);
          increases.push(0);
        } else {
          increases.push(0);
          decreases.push(0);
        }
      }

      return {
        increases,
        decreases
      };
    },
    roundRSI(value: number): number {
      return Math.round(value);
    },
    getRSIStatus(value: number): RSIColorMapping {
      const mapping: RSIColorMapping = {
        background: "green",
        text: "black"
      };

      // Order matters...
      if (value <= 70 && value >= 30) mapping.background = "orange";

      if (value > 70) mapping.background = "red";

      if (value >= 90) {
        mapping.background = "black";
        mapping.text = "white";
      }

      return mapping;
    },
    captialise(value: string) {
      return value.charAt(0).toUpperCase() + value.slice(1);
    },
    rsiBetweenLevels(info: RSIInfo) {
      return info.value >= info.buyPoint && info.value <= info.limitPoint;
    },
    rsiLessThanBuyPoint(info: RSIInfo) {
      return info.value <= info.buyPoint;
    }
  },
  mounted() {
    if (window.location.hostname !== "localhost") {
      this.getRSILevels();
    }
  }
});
</script>

<!-- Component CSS -->
<style lang="stylus">
// Default - Mobile Viewport
.rsi-title, .thought-title
  margin: 1rem 0
  font-size: 1.1rem
  font-weight: bold

.thought-container
  .thought-title
    margin: 0
  .thought-point
    margin: 1rem 0

.btc-rsi-container

  .rsi-answer, .rsi-sub
    font-size: 1.5rem
    text-align: center
    font-weight: 500

  .rsi-sub
    font-size: 0.9rem
    color: grey

  .rsi-container
    display: flex
    justify-content: space-evenly
    align-items: center
    margin-top: 1rem

  .rsi-level
    height: 5rem
    width: 5rem
    border-radius: 50%
    display: flex
    justify-content: center
    align-items: center
    background-color: orange
    font-weight: bold

// Tablet Viewportñ
@media screen and (min-width: 599px) {}

// Desktop Viewport
@media screen and (min-width: 1022px) {}
</style>
