<!-- Component HTML -->
<template>
  <base-card title="Upcoming Cards">
    <template v-slot:info>
      <p>A outline of upcoming cards to be implemented</p>
    </template>
    <template v-slot:main>
      <ul>
        <li><b>Charts</b></li>
        <ul>
          <li>ETH/BTC</li>
          <li>RSI - ETH</li>
          <li>BLX (With Fib Retracement levels if possible)</li>
          <li>Crypto Balances stored in Exchanges</li>
          <li>Alt Coin Cycles</li>
          <ul>
            <li>
              Find Alt Coins that haven't popped on a given date / Display them
            </li>
          </ul>
        </ul>
        <!-- <li><b>NLP Sentiment Analysis on Youtube Videos</b></li>
        <ul>
          <li>DataDash</li>
          <li>Santoshi Tracker</li>
          <li>Santoshi Tracked</li>
          <li>MMCrypto</li>
          <li>DavinchiJ15</li>
        </ul> -->
        <li><b>Stock Market - Crypo Stocks</b></li>
        <ul>
          <li>Argo Blockchain</li>
        </ul>
        <li><b>Twitter - Whale Alert Tracker</b></li>
        <ul>
          <li>BTC</li>
          <li>ETH</li>
        </ul>
        <li><b>Defi Pulse</b></li>
        <li><b>Coin Analysis Page</b></li>
        <ul>
          <li>Fib Retracement Levels (Weekly)</li>
          <li>[COIN]/BTC Chart</li>
          <li>[COIN]/ETH Chart</li>
          <li>[COIN]/GBP Chart</li>
          <li>[COIN]/USD Chart</li>
          <li>(If possible) NLP Sentiment Analysis</li>
          <li>RSI Level (Weekly)</li>
          <li>Crypto Balances stored in Exchanges</li>
        </ul>
        <ul>
          <li><b>Longs/Shorts at Exchanges</b></li>
          <li>
            Shorts in Bitfenix - Commonly associated with Chinese Wales, can be
            used to indicate possible bad news from China
          </li>
        </ul>
      </ul>
    </template>
  </base-card>
</template>

<!-- Component Script -->
<script lang="ts">
import { defineComponent } from "vue";
import BaseCard from "@/blueprint/components/reports/Base-Card.vue";

export default defineComponent({
  name: "Upcoming",
  components: {
    BaseCard
  }
});
</script>

<!-- Component CSS -->
<style lang="stylus">
// Default - Mobile Viewport

// Tablet Viewport
@media screen and (min-width: 599px) {}

// Desktop Viewport
@media screen and (min-width: 1022px) {}
</style>
