import { GetViaProxy } from "./proxy";

export type ShrimpyCandleIntervals = "1h" | "1d";
export type KnownExchanges = "coinbasepro" | "binance";
export type KnownSymbols = "BTC/GBP";

export interface CandleData {
  open: string;
  high: string;
  low: string;
  close: string;
  volume: string;
  time: string;
}

export default class ShrimpyClient {
  private baseUrl = "https://dev-api.shrimpy.io/v1";

  public async GetCandlesFor(
    symbol: KnownSymbols,
    exchange: KnownExchanges,
    interval: ShrimpyCandleIntervals
  ) {
    const symbols = symbol.split("/");

    if (symbols.length === 2) {
      const baseSymbol = symbols[0];
      const quoteSymbol = symbols[1];

      const url = `${this.baseUrl}/exchanges/${exchange}/candles?quoteTradingSymbol=${quoteSymbol}&baseTradingSymbol=${baseSymbol}&interval=${interval}`;
      // TODO: Handle timeouts
      const response = await GetViaProxy<CandleData[]>(url);
      if (response.status === 200) {
        return response.data;
      }
      throw new Error(
        `Unable to get candles from server, status: ${response.status}`
      );
    }

    throw new Error(`Invalid Symbol ${symbol}`);
  }
}
