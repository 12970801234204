
import { defineComponent } from "vue";
import BTCSTF from "@/blueprint/components/reports/BTC-STF/index.vue";
import GreedIndex from "@/blueprint/components/reports/Greed-Index.vue";
import AltIndex from "@/blueprint/components/reports/Alt-Index/index.vue";
import Upcoming from "@/blueprint/components/reports/Upcoming.vue";
import BTCRSI from "@/blueprint/components/reports/BTC-RSI/index.vue";
import TwitterFeed from "@/blueprint/components/reports/Twitter-Feed.vue";
// import TradingViewPrices from "@/blueprint/components/reports/TradingViewPrices.vue";
import BTCCharts from "@/blueprint/components/reports/BTC-Charts.vue";
import Indicators from "../components/reports/Indicators.vue";

export default defineComponent({
  name: "Home",
  components: {
    "greed-index": GreedIndex,
    "alt-index": AltIndex,
    "btc-stf": BTCSTF,
    "btc-rsi": BTCRSI,
    upcoming: Upcoming,
    "twitter-feed": TwitterFeed,
    "btc-charts": BTCCharts,
    indicators: Indicators
    // "trading-view-prices": TradingViewPrices
  }
});
