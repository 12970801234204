
import { defineComponent } from "vue";
import AltIndexBar from "@/blueprint/components/reports/Alt-Index/Alt-Index-Bar.vue";

export default defineComponent({
  name: "Alt-Index-Section",
  components: {
    "alt-bar": AltIndexBar
  },
  props: {
    title: {
      type: String,
      required: true,
      default: ""
    },
    value: {
      type: Number,
      required: true,
      default: 0
    },
    barLeft: {
      type: String,
      required: true,
      default: ""
    },
    barRight: {
      type: String,
      required: true,
      default: ""
    }
  }
});
