
import { defineComponent } from "vue";

export default defineComponent({
  name: "STF-Convergence-Analysis",
  props: {
    tenDaySTF: {
      type: Number,
      required: true,
      default: 0
    },
    fourSixThreeDaySTF: {
      type: Number,
      required: true,
      default: 0
    }
  },
  computed: {
    stfConverged(): boolean {
      const mean = (this.tenDaySTF + this.fourSixThreeDaySTF) / 2;
      const diff = this.tenDaySTF - this.fourSixThreeDaySTF;
      const value = Math.abs((diff / mean) * 100);
      // If the STF difference between 10d and 463d is less than 1% the model has convereged
      return value < 1;
    }
  }
});
