import { render } from "./index.vue?vue&type=template&id=7b03f512"
import script from "./index.vue?vue&type=script&lang=ts"
export * from "./index.vue?vue&type=script&lang=ts"

import "./index.vue?vue&type=style&index=0&id=7b03f512&lang=stylus"
script.render = render

export default script
import QSkeleton from 'quasar/src/components/skeleton/QSkeleton.js';
import qInstall from "../../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QSkeleton});
